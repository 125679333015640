import artikel from "./artikel";
import auth from "./auth";
import contact from "./contact";
import film from "./film";
import foto from "./foto";
import dienstleistungen from "./dienstleistungen";
import pages from "./pages";
import projekt from "./projekt";
import team from "./team";
import axios from "axios";

export default {
    artikel,
    auth,
    contact,
    film,
    foto,
    dienstleistungen,
    pages,
    projekt,
    team,
    upload(id, file) {
        let formData = new FormData();
        formData.append("file", file);
        return axios.post( "/api/images/upload/" + id,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        );
    },
    delete(id) {
        return axios.delete( "/api/images/delete/" + id);
    },
}
