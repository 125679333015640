import api from "../api";

// initial state
const state = {
    contacts: undefined,
    contact: undefined,
}

// getters
const getters = {
    get: () => {
        return state.contact;
    },
    getList: () => {
        return state.contacts;
    },
}

// actions
const actions = {
    request({commit}, id) {
        return new Promise((resolve, reject) => {
            api.contact.get(id).then(res => {
                commit("update", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    requestList({commit}) {
        return new Promise((resolve, reject) => {
            api.contact.getList().then(res => {
                commit("updateList", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    delete({commit}, id) {
        return new Promise((resolve, reject) => {
            api.contact.delete(id).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    edit({commit}, data) {
        return new Promise((resolve, reject) => {
            api.contact.update(data).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    create({commit}, data) {
        return new Promise((resolve, reject) => {
            api.contact.create(data).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
}

// mutations
const mutations = {
    update(data, res) {
        state.contact = res;
    },
    updateList(data, res) {
        state.contacts = res;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}