<template>
    <div id="content" style="margin-top: -3vmin" v-if="page !== undefined && lang !== undefined">
        <div style="padding:0;position:relative">
            <iframe class="mainVideo" width="100%" height="100%" :src="'https://www.youtube-nocookie.com/embed/' + page.videoID + '?loop=1'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div id="main">
            <h1 class="centerText">{{ page.title }}</h1>
            <div><hr/></div>
            <div class="col_1-3 wrapMobile">
                <v-img :src="'/api/images/get/startseite'" alt="Startseite"/>
            </div>
            <div>
                <p style="text-align: left; margin: 2vmin" v-for="(text, i) in page.text" :key="i" v-html="text"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Startseite",
        data: () => ({
            name: "Startseite",
            page: undefined,
        }),
        props: {
            lang: String,
        },
        created() {
            this.load();
        },
        computed: {
            ...mapGetters("pages", ["getStartseite"]),
            id() {
                return this.lang + "-" + this.name;
            },
        },
        methods: {
            ...mapActions("pages", ["requestPage"]),
            load() {
                this.requestPage(this.id).then(() => {
                    this.page = this.getStartseite;
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    h1 {
        margin-left: $margin;
    }

    .fitHeight {
        height: fit-content;
    }
</style>
