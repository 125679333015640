<template>
    <div class="content" v-if="page !== undefined && lang !== undefined"><!-- Head -->
        <h1 class="centerText">{{ page.title }}</h1>
        <p class="centerText">{{ page.text }}</p>

        <!-- Content -->
        <span v-if="films !== undefined">
            <v-row no-gutters v-for="(chunk, i) in chunk(films.slice(0), 3)" :key="i">
                <v-col v-for="film in chunk" :key="film.id" sm="4">
                    <v-card flat color="white" class="ma-2">
                        <span v-if="film.videoID === null || film.videoID === ''">
                            <a v-if="film.link !== null && film.link !== ''" :href="film.link" target="_blank">
                                <v-img :src="'/api/images/get/film' + film.id"/>
                            </a>
                            <v-img v-else :src="'/api/images/get/film' + film.id"/>
                        </span>

                        <iframe class="video" v-else width="100%" height="100%" :src="'https://www.youtube-nocookie.com/embed/' + film.videoID + '?loop=1'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <v-card-title>{{ film.title[lang] }}</v-card-title>
                        <v-card-text>
                            <div v-for="(text, j) in film.text[lang]" :key="j" v-html="text"/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </span>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Film",
        data: () => ({
            name: "Film",
            page: undefined,
            films: undefined,
        }),
        props: {
            lang: String,
        },
        created() {
            this.requestPage(this.id).then(() => {
                this.page = this.getPage;
            })
            this.requestList().then(() => {
                this.films = this.getList;
            })
        },
        computed: {
            ...mapGetters("pages", ["getPage"]),
            ...mapGetters("film", ["getList"]),
            id() {
                return this.lang + "-" + this.name;
            },
        },
        methods: {
            ...mapActions("pages", ["requestPage"]),
            ...mapActions("film", ["requestList"]),
            chunk(array, size) {
                const chunked_arr = [];
                for (let i = 0; i < array.length; i++) {
                    const last = chunked_arr[chunked_arr.length - 1];
                    if (!last || last.length === size) {
                        chunked_arr.push([array[i]]);
                    } else {
                        last.push(array[i]);
                    }
                }
                return chunked_arr;
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .noBreak {
            white-space: nowrap;
        }

        .centerText {
            text-align: center;
            max-width: 66%;
            margin-left: auto;
            margin-right: auto;
        }

        .video {
            height: 52vw;

            @media #{$breakToTablet} {
                height: 17.5vw;
            }
        }
    }
</style>