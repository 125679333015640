import axios from "axios";

export default {
    check() {
        return axios.get("/api/auth/test/check");
    },
    login(user, pass) {
        const credentials = { username: user, password: pass };
        return axios.post("/api/auth/login", credentials);
    }
}
