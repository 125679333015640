<template>
    <v-container v-if="list !== []">
        <v-row no-gutters>
            <v-col style="display: flex">
                <v-btn style="margin: auto" @click="save" :loading="loading" color="primary">Speichern</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters v-for="p in custom" :key="p">
            <v-col v-if="list['de-' + p] !== undefined">
                <v-card class="ma-2">
                    <v-card-title>{{ p }}</v-card-title>
                    <v-row no-gutters>
                        <v-col sm="6">
                            <v-card-subtitle>Deutsch</v-card-subtitle>
                            <v-card-text>
                                <v-row no-gutters style="display: flex">
                                    <v-col>
                                        <v-text-field label="Titel" filled v-model="list['de-' + p].title"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-textarea label="Text" filled auto-grow rows="1" v-if="p === 'Startseite'" v-model="list['de-' + p].text"></v-textarea>
                                        <v-text-field label="Text" filled v-else v-model="list['de-' + p].text"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="p === 'Startseite'" no-gutters style="display: flex">
                                    <v-col>
                                        <v-text-field label="Video" filled v-model="list['de-' + p].videoID"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="p === 'Startseite'" no-gutters style="display: flex">
                                    <v-col>
                                        <v-file-input v-model="image" show-size label="Foto" filled accept="image/*" prepend-icon="mdi-camera"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-col>
                        <v-col sm="6">
                            <v-card-subtitle>English</v-card-subtitle>
                            <v-card-text>
                                <v-row no-gutters style="display: flex">
                                    <v-col>
                                        <v-text-field label="Title" filled v-model="list['en-' + p].title"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-textarea label="Text" filled auto-grow rows="1" v-if="p === 'Startseite'" v-model="list['en-' + p].text"></v-textarea>
                                        <v-text-field label="Text" filled v-else v-model="list['en-' + p].text"></v-text-field>                            </v-col>
                                </v-row>
                                <v-row  v-if="p === 'Startseite'" no-gutters style="display: flex">
                                    <v-col>
                                        <v-text-field label="Video" filled v-model="list['en-' + p].videoID"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Seiten",
        data: () => ({
            list: [],
            image: null,
            loading: false,
            custom: [
                "Startseite",
                "Team",
                "Film",
                "Dienstleistungen",
                "Foto",
                "Projekte",
                "News",
            ]
        }),
        created() {
            this.load();
        },
        computed: {
            ...mapGetters("pages", ["getList"]),
        },
        methods: {
            ...mapActions("pages", ["requestList", "edit"]),
            ...mapActions(["uploadImage"]),

            load() {
                this.requestList().then(() => {
                    this.list = this.getList;
                })
            },
            save(){
                for(let p in this.list) {
                    this.edit(this.list[p]);
                }
                if(this.image !== null) {
                    let image = {
                        id: "startseite",
                        file: this.image,
                    }
                    this.loading = true;
                    this.uploadImage(image).then(() => {
                        this.loading = false;
                        this.image = null;
                    });
                }

                this.load();
            }
        }
    }
</script>
