<template>
    <div class="content" v-if="page !== undefined && lang !== undefined"><!-- Head -->
        <h1 class="centerText">{{ page.title }}</h1>
        <p class="centerText">{{ page.text }}</p>

        <!-- Content -->
        <span v-if="projekte !== undefined">
            <v-card flat color="white" class="ma-4" v-for="(projekt, i) in projekte" :key="projekt.id">
                <v-row no-gutters :class="{ left: isEven(i), right: !isEven(i)}">
                    <v-col sm="4" class="minWidth">
                        <span v-if="projekt.videoID === null || projekt.videoID === ''">
                            <a v-if="projekt.link !== null && projekt.link !== ''" :href="projekt.link" target="_blank">
                                <v-img :src="'/api/images/get/projekt' + projekt.id"/>
                            </a>
                            <v-img v-else :src="'/api/images/get/projekt' + projekt.id"/>
                        </span>
                        <iframe class="video" v-else width="100%" height="100%" :src="'https://www.youtube-nocookie.com/embed/' + projekt.videoID + '?loop=1'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </v-col>
                    <v-col sm="6">
                        <v-card-title style="display: block">{{ projekt.title[lang] }}</v-card-title>
                        <v-card-text>
                            <div style="text-align: left;" v-for="(text, j) in projekt.text[lang]" :key="j" v-html="text"/>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </span>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Projekte",
        data: () => ({
            name: "Projekte",
            page: undefined,
            projekte: undefined,
        }),
        props: {
            lang: String,
        },
        created() {
            this.requestPage(this.id).then(() => {
                this.page = this.getPage;
            })
            this.requestList().then(() => {
                this.projekte = this.getList;
            })
        },
        computed: {
            ...mapGetters("pages", ["getPage"]),
            ...mapGetters("projekt", ["getList"]),
            id() {
                return this.lang + "-" + this.name;
            },
        },
        methods: {
            ...mapActions("pages", ["requestPage"]),
            ...mapActions("projekt", ["requestList"]),
            chunk(array, size) {
                const chunked_arr = [];
                for (let i = 0; i < array.length; i++) {
                    const last = chunked_arr[chunked_arr.length - 1];
                    if (!last || last.length === size) {
                        chunked_arr.push([array[i]]);
                    } else {
                        last.push(array[i]);
                    }
                }
                return chunked_arr;
            },
            isEven(num) {
                return num % 2 === 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .left {
            flex-direction: row;
            text-align: left;
        }

        .right {
            flex-direction: row-reverse;
            text-align: right;
        }

        .noBreak {
            white-space: nowrap;
        }

        .centerText {
            text-align: center;
            max-width: 66%;
            margin-left: auto;
            margin-right: auto;
        }

        .minWidth {
            min-width: 100%;

            @media #{$breakToTablet} {
                min-width: unset;
            }
        }
        .video {
            height: 52vw;

            @media #{$breakToTablet} {
                height: 17.5vw;
            }
        }
    }
</style>
