import api from "../api";

// initial state
const state = {
    jobs: undefined,
    teams: undefined,
    team: undefined,
}

// getters
const getters = {
    get: () => {
        return state.team;
    },
    getList: () => {
        return state.teams;
    },
    getJobs: () => {
        return state.jobs;
    },
}

// actions
const actions = {
    request({commit}, id) {
        return new Promise((resolve, reject) => {
            api.team.get(id).then(res => {
                commit("update", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    requestList({commit}) {
        return new Promise((resolve, reject) => {
            api.team.getList().then(res => {
                commit("updateList", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    requestJobs({commit}) {
        return new Promise((resolve, reject) => {
            api.team.getJobs().then(res => {
                commit("updateJobs", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    delete({commit}, id) {
        return new Promise((resolve, reject) => {
            api.team.delete(id).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    moveDown({commit}, id) {
        return new Promise((resolve, reject) => {
            api.team.moveDown(id).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    edit({commit}, team) {
        return new Promise((resolve, reject) => {
            api.team.update(team).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    create({commit}, data) {
        return new Promise((resolve, reject) => {
            api.team.create().then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
}

// mutations
const mutations = {
    update(data, res) {
        res.text = JSON.parse(res.text);
        res.position = JSON.parse(res.position);
        state.team = res;
    },
    updateList(data, res) {
        if(res !== undefined) {
            state.teams = [];
            res.forEach(r => {
                if(r.text !== undefined || r.text !== "") {
                    r.text = JSON.parse(r.text);
                }
                if(r.position !== undefined || r.position !== "") {
                    r.position = JSON.parse(r.position);
                }
                state.teams.push(r);
            });
        }
    },
    updateJobs(data, res) {
        if(res.text !== undefined || res.text !== "") {
            res.text = JSON.parse(res.text);
            state.jobs = res.text;
        }
    },
    // eslint-disable-next-line no-unused-vars
    upload({commit}, image) {
        return new Promise((resolve, reject) => {
            api.upload(image.id, image.file).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}