<template>
    <iframe width="100%" height="100%" src="https://tour-uk.metareal.com/apps/player?asset=4b9d9de7-96cb-4cef-b229-83e60e868846" frameborder="0" allow="xr-spatial-tracking; accelerometer; magnetometer; gyroscope" allowvr="yes" allowfullscreen="yes"></iframe>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
