import axios from "axios";

export default {
    getList() {
        return axios.get("/api/projekt/list");
    },
    get(id) {
        return axios.get("/api/projekt/get/" + id);
    },
    create() {
        let cmd = {
            title: "{\"de\":\"Titel DE\",\"en\":\"Title EN\"}\t",
            text: "{\"de\":[\"Text DE 1\",\"Text DE2\"],\"en\":[\"Text EN 1\",\"Text EN 2\"]}\t",
            videoId: null,
            link: null,
        }
        return axios.post("/api/projekt/create", cmd);
    },
    delete(id) {
        return axios.delete("/api/projekt/delete/" + id);
    },
    moveDown(id) {
        return axios.post("/api/projekt/move/" + id);
    },
    update(object) {
        return axios.put("/api/projekt/update/" + object.id, object);
    },
}
