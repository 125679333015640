<template>
    <cookie-law>
        <div slot-scope="props" class="Cookie Cookie--bottom Cookie--dark-lime">
            <div class="Cookie__content">
                Diese Website verwendet rein technische Cookies 🍪, welche zur Verwendung der Seite notwendig sind.
            </div>
            <button class="Cookie__button" @click="props.accept"><span>Verstanden</span></button>
        </div>
    </cookie-law>
</template>

<script>
	import CookieLaw from 'vue-cookie-law'

	export default {
		name: "CookieBanner",
		components: { CookieLaw },
	}
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    .Cookie {
        overflow: visible!important;
    }

    .Cookie--base {
        background-color: $foreground!important;
    }

    .Cookie--dark-lime {
        background-color: $foreground!important;
        box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.5);
    }

    .Cookie__content {
        color: $white!important;
    }

    .Cookie__button {
        background-color: $mainColor!important;
        color: $white!important;

        &:hover {
            background-color: $white!important;
            color: $mainColor!important;
        }
    }

</style>