import axios from "axios";

export default {
    getList() {
        return axios.get("/api/foto/list");
    },
    get(id) {
        return axios.get("/api/foto/get/" + id);
    },
    create() {
        let cmd = {
            title: "{\"de\":\"Titel DE\",\"en\":\"Title EN\"}\t",
            text: "{\"de\":[\"Text DE 1\",\"Text DE2\"],\"en\":[\"Text EN 1\",\"Text EN 2\"]}\t",
            videoId: null,
            link: null,
        }
        return axios.post("/api/foto/create", cmd);
    },
    delete(id) {
        return axios.delete("/api/foto/delete/" + id);
    },
    moveDown(id) {
        return axios.post("/api/foto/move/" + id);
    },
    update(object) {
        return axios.put("/api/foto/update/" + object.id, object);
    },
}
