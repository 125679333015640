import axios from "axios";

export default {
    get(id) {
        return axios.get("/api/artikel/" + id);
    },
    getList() {
        return axios.get("/api/artikel/list");
    },
    create(cmd) {
        return axios.post("/api/artikel/create", cmd);
    },
    delete(id) {
        return axios.delete("/api/artikel/delete/" + id);
    },
    update(object) {
        return axios.put("/api/artikel/update/" + object.id, object);
    },
}
