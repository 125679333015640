import api from "../api";

// initial state
const state = {
    page: undefined,
    list: [],
}

// getters
const getters = {
    getPage: () => {
        return state.page;
    },
    getList: () => {
        return state.list;
    },
    getStartseite: () => {
        let start = state.page;
        start.text = JSON.parse(start.text);
        return start;
    },
}

// actions
const actions = {
    requestPage({commit}, id) {
        return new Promise((resolve, reject) => {
            api.pages.get(id).then(res => {
                commit("updatePage", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    requestList({commit}) {
        return new Promise((resolve, reject) => {
            api.pages.list().then(res => {
                commit("updateList", res.data)
                resolve()
            }).catch(() => {
                commit("updateList", [])
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    edit({commit}, page) {
        return new Promise((resolve, reject) => {
            api.pages.update(page).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
}

// mutations
const mutations = {
    updatePage(data, res) {
        if(res !== undefined) {
            state.page = res;
        }
    },
    updateList(data, res) {
        if(res !== undefined) {
            state.list = [];
            for(let r in res) {
                if(res[r].id === "de-Startseite" || res[r].id === "en-Startseite") {
                    res[r].text = JSON.parse(res[r].text);
                    let cache = "";
                    for(let t in res[r].text) {
                        cache += res[r].text[t] + "\n";
                    }
                    res[r].text = cache.slice(0, -1);
                }
                state.list[res[r].id] = res[r];
            }
            //state.list = res;
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}