<template>
    <v-container v-if="list !== []">
        <v-dialog v-model="dialog" width="fit-content">
            <v-card max-width="400px" style="margin: auto">
                <v-card-title>Möchtest du wirklich löschen?</v-card-title>
                <v-card-text>Du kannst diesen Vorgang nicht rückgänig machen!</v-card-text>
                <v-card-actions>
                    <v-btn @click="dialog = false">Abbrechen</v-btn>
                    <v-spacer/>
                    <v-btn @click="del()" color="error">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters>
            <v-col style="display: flex">
                <v-btn class="my-2" style="margin-right: 5px;margin-left: auto" @click="save" color="primary">Speichern</v-btn>
                <v-btn class="my-2" style="margin-left: 5px; margin-right: auto" @click="add" color="primary">Neuer Mitarbeiter</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-textarea filled label="Jobs" v-model="jobs"/>
            </v-col>
        </v-row>
        <v-row no-gutters v-for="(t, i) in list" :key="t + i">
            <v-col>
                <v-toolbar class="ma-1">
                    <v-toolbar-title style="overflow: hidden">{{ t.name }}</v-toolbar-title>
                    <v-spacer/>
                    <v-btn @click="moveD(t.id)" icon><v-icon>mdi-arrow-down</v-icon></v-btn>
                    <v-divider vertical/>
                    <v-btn @click="$router.push('/cms/edit/team/' + t.id)" icon><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-divider vertical/>
                    <v-btn @click="dialog = true; selected = t.id" icon><v-icon>mdi-delete</v-icon></v-btn>
                </v-toolbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Team",
        data: () => ({
            list: [],
            jobs: [],
            selected: null,
            dialog: false,
        }),
        created() {
            this.load();
        },
        computed: {
            ...mapGetters("team", ["getList", "getJobs"]),
        },
        methods: {
            ...mapActions("team", ["requestList", "requestJobs", "delete", "edit", "create", "moveDown"]),
            ...mapActions(["deleteImage"]),
            load() {
                this.requestList().then(() => {
                    this.list = this.getList;
                })
                this.requestJobs().then(() => {
                    let list = this.getJobs;
                    let cache = "";
                    for(let t in list) {
                        cache += list[t] + "\n";
                    }
                    this.jobs = cache.slice(0, -1);
                })
            },
            del( ) {
                this.dialog = false;
                this.delete(this.selected).then(() => {
                    this.deleteImage("team" + this.selected);
                    this.load();
                });
            },
            moveD(id) {
                this.moveDown(id).then(() => {
                    this.load();
                })
            },
            save() {
                let array = this.jobs.split("\n");
                let cmd = {
                    id: 1,
                    name: "Jobs",
                    position: null,
                    text: JSON.stringify(array),
                }
                this.edit(cmd).then(() => {
                    this.load();
                });
            },
            add() {
                this.create().then(() => {
                    this.load();
                })
            }
        }
    }
</script>