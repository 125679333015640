import axios from "axios";

export default {
    getList() {
        return axios.get("/api/contact/list");
    },
    get(id) {
        return axios.get("/api/contact/get/" + id);
    },
    create(id) {
        let cmd = {
            id: id,
            idEng: id,
            text: "Text",
        }
        return axios.post("/api/contact/create", cmd);
    },
    delete(id) {
        return axios.delete("/api/contact/delete/" + id);
    },
    update(object) {
        return axios.put("/api/contact/update/" + object.id, object);
    },
}
