<template>
    <div class="content" v-if="page !== undefined && lang !== undefined">
        <!-- Head -->
        <h1 class="centerText">{{ page.title }}</h1>
        <p class="centerText">{{ page.text }}</p>

        <!-- Content -->
        <span v-for="(mitarbeiter, i) in team" :key="mitarbeiter + i">
            <v-row no-gutters :class="{ left: isEven(i), right: !isEven(i)}">
                <v-col sm="4">
                    <img class="mitFoto" style="margin-top: 2vmin" :src="'/api/images/get/team' + mitarbeiter.id">
                </v-col>
                <v-col sm="8" class="widthMobile">
                    <div class="box" :class="{ left: isEven(i), right: !isEven(i)}">
                        <h1>{{ mitarbeiter.name }}</h1>
                        <h2>{{ mitarbeiter.position[lang] }}</h2>
                        <p style="text-align: left;" v-for="(t, j) in mitarbeiter.text[lang]" :key="t + i + j" v-html="t"/>
                    </div>
                </v-col>
                <v-spacer/>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <hr/>
                </v-col>
            </v-row>
        </span>

        <div v-if="jobs !== undefined">
            <h1 class="centerText">Jobs</h1>
            <p class="centerText" v-for="job in jobs" :key="job" v-html="job"/>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Team",
        data: () => ({
            name: "Team",
            page: undefined,
            jobs: undefined,
            team: undefined,
        }),
        props: {
            lang: String,
        },
        created() {
            this.requestPage(this.id).then(() => {
                this.page = this.getPage;
            })
            this.requestList().then(() => {
                this.team = this.getList;
            })
            this.requestJobs().then(() => {
                this.jobs = this.getJobs;
            })
        },
        computed: {
            ...mapGetters("pages", ["getPage"]),
            ...mapGetters("team", ["getList", "getJobs"]),
            id() {
                return this.lang + "-" + this.name;
            },
        },
        methods: {
            ...mapActions("pages", ["requestPage"]),
            ...mapActions("team", ["requestList", "requestJobs"]),
            isEven(num) {
                return num % 2 === 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        src: url("/Montserrat.woff") format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    .mitFoto {
        min-width: 94vw;

        @media #{$breakToTablet} {
            min-width: 0;
        }

    }

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .left {
            flex-direction: row;
            text-align: left;
        }

        .right {
            flex-direction: row-reverse;
            text-align: right;
        }

        .widthMobile {
            min-width: 100%;
            @media #{$breakToTablet} {
                min-width: 65%;
            }

            .box {
                padding-right: 0;
                padding-left: 0;

                &.left {
                    @media #{$breakToTablet} {
                        padding-right: 13vw;
                    }
                }
                &.right {
                    @media #{$breakToTablet} {
                        padding-left: 13vw;
                    }
                }

            }
        }

        .noBreak {
            white-space: nowrap;
        }

        .centerText {
            text-align: center;
            max-width: 66%;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>
