<template>
    <v-container v-if="elem !== undefined">
        <v-row no-gutters>
            <v-col style="display: flex">
                <v-btn class="my-2" style="margin-right: 5px;margin-left: auto" @click="save" color="primary">Speichern</v-btn>
                <v-btn class="my-2" style="margin-left: 5px; margin-right: auto" @click="$router.go(-1)" color="danger">Zurück</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-text-field class="mx-1" label="Titel Deutsch" v-model="elem.title['de']" filled/>
            </v-col>
            <v-col>
                <v-text-field class="mx-1" label="Title English" v-model="elem.title['en']" filled/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-textarea class="mx-1" label="Text Deutsch" v-model="elem.text['de']" filled auto-grow rows="1"/>
            </v-col>
            <v-col>
                <v-textarea class="mx-1" label="Text English" v-model="elem.text['en']" filled auto-grow rows="1"/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-text-field label="Video (Wenn leer => Bild)" v-model="elem.videoID" filled/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-file-input label="Bild" v-model="image" filled show-size/>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="elem.videoID !== undefined && elem.videoID !== ''">
            <v-col>
                <v-text-field class="mx-1" label="ULR (bei Bild)" v-model="elem.link" filled/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "EditFoto",
        data: () => ({
            elem: undefined,
            image: null,
        }),
        created() {
            this.load();
        },
        computed: {
            ...mapGetters("foto", ["get"]),
        },
        methods: {
            ...mapActions("foto", ["request", "edit"]),
            ...mapActions(["uploadImage"]),
            load() {
                this.request(this.$route.params.id).then(() => {
                    this.elem = this.get;
                    this.elem.title = JSON.parse(this.elem.title);
                    let cache = "";
                    for(let t in this.elem.text["de"]) {
                        cache += this.elem.text["de"][t] + "\n";
                    }
                    this.elem.text["de"] = cache.slice(0, -1);
                    let cacheEN = "";
                    for(let t in this.elem.text["en"]) {
                        cacheEN += this.elem.text["en"][t] + "\n";
                    }
                    this.elem.text["en"] = cacheEN.slice(0, -1);
                })
            },
            save() {
                let text = {
                    "de": this.elem.text["de"].split("\n"),
                    "en": this.elem.text["en"].split("\n")
                }
                let cmd = {
                    id: this.$route.params.id,
                    title: JSON.stringify(this.elem.title),
                    text: JSON.stringify(text),
                    videoID: this.elem.videoID,
                    link: this.elem.link,
                }
                this.edit(cmd).then(() => {
                    if(this.image !== null) {
                        let image = {
                            id: "foto" + this.$route.params.id,
                            file: this.image,
                        }
                        this.loading = true;
                        this.uploadImage(image).then(() => {
                            this.image = null;
                        });
                    }
                    this.$router.go(-1);
                });
            },
        }
    }
</script>