<template>
    <nav>
        <ul>
            <li id="logo">
                <router-link :to="'/' + lang + '/'" id="logoText" class="router">
                    <img src="@/main/vue/assets/logo.min.png" alt="Logo">
                </router-link>
            </li>
            <li class="animated" v-for="(elem, i) in navElem[lang]" :key="elem">
                <router-link :to="'/' + lang + '/' + navElem.de[i] + '/'" :id="elem"  class="router">
                    <a>{{ elem.slice(0, 1).toUpperCase() + elem.slice(1) }}</a>
                </router-link>
            </li>
            <li id="flag" class="animated" style="float:right; margin-right: 1vmin">
                <a class="router" @click="changeLang">
                    <img :src="'/images/' + lang + '.svg'">
                </a>
            </li>
            <li id="menu" style="float: right">
                <a class="router" @click="mobileNav">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id="mobileNavButtonSVG" class="noAnimation"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                </a>
            </li>
        </ul>
        <div id="overlay" class="animated">
            <div id="overlay-content">
                <router-link :to="'/' + lang + '/' + navElem.de[i] + '/'" :id="elem" v-for="(elem, i) in navElem[lang]" :key="elem">
                    <a @click="closeNav">{{ elem.slice(0, 1).toUpperCase() + elem.slice(1) }}</a>
                </router-link>
                <a>
                    <a v-if="lang === 'de'" @click="changeLang">Sprache: Deutsch</a>
                    <a v-else @click="changeLang">Language: English</a>
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
		name: "Navigation",
        props: {
            lang: String,
        },
        data: () => ({
            navElem: {
                "de": ["team", "film", "foto", "leistungen", "projekte", "aktuelles"],
                "en": ["team", "video", "photo", "services", "projects", "news"],
            },
        }),
        methods: {
            changeLang() {
                let path = this.$route.path;
                if (this.$route.params.lang === "de") {
                    path = path.replace("/de/", "/en/");
                } else {
                    path = path.replace("/en/", "/de/");
                }
                this.$router.push(path);
                this.$router.go();
            },
            mobileNav() {
                if(document.getElementById("overlay").style.width.toString() === "0px") {
                    document.getElementById("overlay").style.width = "100%";
                    document.getElementById("overlay").style.right = "0";
                    document.getElementById("mobileNavButtonSVG").style.color = "#fff";
                    document.getElementsByTagName("html")[0].style.overflowY = "hidden";
                } else {
                    this.closeNav();
                }
            },
            closeNav() {
                document.getElementsByTagName("html")[0].style.overflowY = "scroll";
                document.getElementById("overlay").style.width = "0";
                document.getElementById("overlay").style.right = "-10%";
            }
        }
	}
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    @font-face {
        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 400;
        src: url("/Merriweather.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    nav {
        ul {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;

            li {
                display: none;
                height: 10vmin;
                margin: $margin;
                padding: 0;
                border-radius: 10vmin;
                border-color: $foreground;
                border-style: solid;
                border-width: 0.5vmin;
                background-color: $white;

                @media #{$breakToTablet} {
                    height: 6vmin;
                    margin: 1.25vmin 0.25vmin 1.25vmin 0.25vmin;
                    border-width: 0.25vmin;
                }
                @media #{$breakToPC} {
                    display: inline-block;
                    height: 5vmin;
                    margin: 1vmin 0.25vmin;
                }
                @media #{$breakToTV} {
                    display: inline-block;
                    margin: 1vmin 0.25vmin;
                }

                &:hover {
                    background-color: $foreground;
                    cursor: pointer;
                    .router a {
                        color: $background;
                    }
                }

                &:first-child {display: inline-block;}
                &:last-child {
                    display: inline-block;
                    @media #{$breakToPC} {
                        display: none;
                    }
                }

                .router {
                    height: 100%;
                    width: 100%;
                    padding: 1vmin 3.5vmin;
                    display: flex;
                    box-sizing: border-box;
                    text-decoration: none;

                    @media #{$breakToTablet} {
                        padding: 1vmin 2vmin 1vmin 2vmin;
                    }
                    @media #{$breakToPC} {
                        padding: 0.15vmin 1vmin 0.15vmin 1vmin;
                    }
                    @media #{$breakToTV} {
                        padding: 0.15vmin 1vmin 0.15vmin 1vmin;
                    }

                    a {
                        color: $foreground;
                        font-family: 'Merriweather', serif;
                        font-size: 1rem;
                        font-weight: 100;
                        letter-spacing: 0.05rem;
                        text-align: center;
                        text-decoration: none;
                        line-height: 0;
                        margin: auto;

                        @media #{$breakToTV} {
                            font-size: 0.825rem;
                        }
                    }

                }
            }

            #logo {
                border-color: $white;

                a {
                    img {
                        border-color: $white;
                        height: 100%;
                        width: auto;
                    }
                }

                &:hover {
                    background-color: $white;
                    a {
                        color: $text;
                    }
                }
            }

            #flag {
                border: 0;
                margin-left: auto;

                img {
                    height: 100%;
                    width: auto;
                    border-radius: 10vmin;
                    border-color: $white;
                    border-style: solid;
                    border-width: 0.5vmin;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $white;
                }
            }
        }
    }

    #menu{
        background: $foreground;
        margin-left: auto;
        width: 10vmin;

        a {
            padding: 2vmin;
            color: $white;

            @media #{$breakToTablet} {
                padding: 1vmin;
            }
        }
    }

    #overlay{
        height: calc(100% - 16vmin);
        width: 0;
        position: fixed;
        z-index: 1;
        top: 16vmin;
        right: -10%;
        background: $foreground;

        @media #{$breakToTablet} {
            height: calc(100% - 11vmin);
            top: 11vmin;
        }
        @media #{$breakToPC} {

        }
    }

    #overlay-content {
        position: relative;
        top: 50%;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        transform: translate(0, -60%);

        a {
            text-decoration: none;
            a {
                padding: 1vmin;
                color: white;
                display: block;
                font-family: 'Merriweather', serif;
                font-size: 5vmin;
                letter-spacing: 0.5vmin;
                line-height: 10vmin;
            }
        }
    }
</style>
