<template>
    <footer v-if="contact !== undefined && contact.length > 0">
        <div id="contact">
            <h2 class="centerText">Social Media:</h2>
            <div id="social" class="split">
                <div class="centerText socialIcons">
                    <a href="https://www.instagram.com/malte.mersch" class="fa fa-instagram rmMarV" target="_blank"></a>
                </div>
                <div class="centerText socialIcons">
                    <a href="https://www.youtube.com/channel/UCDr4tPpZq8dDgAk_QDaGIug" class="fa fa-youtube rmMarV" target="_blank"></a>
                </div>
                <div class="centerText socialIcons">
                    <a href="https://de.linkedin.com/in/malte-mersch-577669161" class="fa fa-linkedin rmMarV" target="_blank"></a>
                </div>
                <div class="centerText socialIcons">
                    <a :href="'mailto:' + mail" class="fa fa-envelope rmMarV"></a>
                </div>
            </div>
            <h2 v-if="lang === 'de'" class="centerText">Hier kannst du Kontakt mit mir aufnehmen:</h2>
            <h2 v-else class="centerText">You can contact me here:</h2>

            <!-- Foreach contact -->
            <h4 class="centerText" v-for="(c, i) in contact" :key="c + i">
                <span v-if="lang === 'de'">{{ c.id }}</span>
                <span v-else>{{ c.idEng }}</span>
                <br/><br/><a style='color: white'> {{ c.text }} </a>
            </h4>
        </div>

        <div class="copyright">
            <a href="/de/impressum" id="imprint">Impressum und Datenschutzerklärung</a>
            <a style="cursor: default">Copyright 2019-{{ new Date().getFullYear() }}, Malte Mersch</a>
            <a href="/AGB.pdf" target="_blank">AGB</a>
        </div>
    </footer>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
		name: "Footer",
        data: () => ({
            contact: undefined,
            mail: undefined,
        }),
        props: {
            lang: String,
        },
        computed: {
            ...mapGetters("contact", ["getList"]),
        },
        created() {
            this.requestList().then(() => {
                this.contact = this.getList;
                this.contact.forEach(e => {
                    if(e.id === "Mail") {
                        this.mail =  e.text;
                    }
                });
            })
        },
        methods: {
            ...mapActions("contact", ["requestList"]),
        }
	}
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    .centerText {
        text-align: center;
    }

    .socialIcons {
        margin: $margin;
    }

    #contact {
        background: $foreground;
        border-top: solid 0.25vmin #e5e5e5;
        border-bottom: solid 0.25vmin #e5e5e5;
    }

    #social {
        display: flex;
        justify-content: center;
    }

    footer {
        .copyright {
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                font-size: 0.75rem;
                line-height: 1rem;
                color: darkgrey;
                text-align: center;
                margin: 0.5rem 1rem;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .fa {
        padding: 1.25rem;
        font-size: 1.56rem;
        text-align: center;
        width: 4rem;
        height: 4rem;
        display: flex;
        text-decoration: none;
        border-radius: 50%;
        border-width: 0;
        border-style: solid;
        background-color: #ff8a65;
        color: white;
        font-family: "FontAwesome", sans-serif;

        &:hover {
            background: #fff;
        }

        &:before {
            margin: auto;
        }
    }

    .fa-instagram {
        &:hover {
            color: #833AB4;
        }
    }

    .fa-twitter {
        &:hover {
            color: #55ACEE;
        }
    }

    .fa-envelope {
        &:hover {
            color: #000000;
        }
    }

    .fa-youtube {
        &:hover {
            color: #bb0000;
        }
    }
    .fa-linkedin {
        &:hover {
            color: #2867B2;
        }
    }
</style>
