<template>
    <v-container v-if="elem !== undefined">
        <v-row no-gutters>
            <v-col style="display: flex">
                <v-btn class="my-2" style="margin-right: 5px;margin-left: auto" @click="save" color="primary">Speichern</v-btn>
                <v-btn class="my-2" style="margin-left: 5px; margin-right: auto" @click="$router.go(-1)" color="danger">Zurück</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-text-field class="mx-1" label="Text" v-model="elem.idEng" filled/>
                <v-text-field class="mx-1" label="Text" v-model="elem.text" filled/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "EditContact",
        data: () => ({
            elem: undefined,
        }),
        created() {
            this.load();
        },
        computed: {
            ...mapGetters("contact", ["get"]),
        },
        methods: {
            ...mapActions("contact", ["request", "edit"]),
            load() {
                this.request(this.$route.params.id).then(() => {
                    this.elem = this.get;
                })
            },
            save() {
                let cmd = {
                    id: this.$route.params.id,
                    idEng: this.elem.idEng,
                    text: this.elem.text,
                }
                this.edit(cmd).then(() => {
                    this.$router.go(-1);
                });
            },
        }
    }
</script>