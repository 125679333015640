import api from "../api";

// initial state
const state = {
    artikel: undefined,
    artikels: undefined,
}

// getters
const getters = {
    get: () => {
        return state.artikel;
    },
    getList: () => {
        return state.artikels;
    },
}

// actions
const actions = {
    request({commit}, id) {
        return new Promise((resolve, reject) => {
            api.artikel.get(id).then(res => {
                commit("update", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    requestList({commit}) {
        return new Promise((resolve, reject) => {
            api.artikel.getList().then(res => {
                commit("updateList", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    delete({commit}, id) {
        return new Promise((resolve, reject) => {
            api.artikel.delete(id).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    edit({commit}, cmd) {
        return new Promise((resolve, reject) => {
            api.artikel.update(cmd).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    create({commit}, cmd) {
        return new Promise((resolve, reject) => {
            api.artikel.create(cmd).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
}

// mutations
const mutations = {
    update(data, res) {
        if(res !== undefined) {
            state.artikel = res;
        }
    },
    updateList(data, res) {
        if(res !== undefined) {
            state.artikels = [];
            res.forEach(r => {
                state.artikels.push(r);
            });
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}