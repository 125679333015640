import api from "../api";

// initial state
const state = {
    projekts: undefined,
    projekt: undefined,
}

// getters
const getters = {
    get: () => {
        return state.projekt;
    },
    getList: () => {
        return state.projekts;
    },
}

// actions
const actions = {
    request({commit}, id) {
        return new Promise((resolve, reject) => {
            api.projekt.get(id).then(res => {
                commit("update", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    requestList({commit}) {
        return new Promise((resolve, reject) => {
            api.projekt.getList().then(res => {
                commit("updateList", res.data)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    delete({commit}, id) {
        return new Promise((resolve, reject) => {
            api.projekt.delete(id).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    moveDown({commit}, id) {
        return new Promise((resolve, reject) => {
            api.projekt.moveDown(id).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    edit({commit}, data) {
        return new Promise((resolve, reject) => {
            api.projekt.update(data).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    create({commit}, data) {
        return new Promise((resolve, reject) => {
            api.projekt.create().then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },
}

// mutations
const mutations = {
    update(data, res) {
        res.text = JSON.parse(res.text);
        state.projekt = res;
    },
    updateList(data, res) {
        if(res !== undefined) {
            state.projekts = [];
            res.forEach(r => {
                if(r.title !== undefined || r.title !== "" &&
                    r.text !== undefined || r.text !== "") {

                    r.title = JSON.parse(r.title);
                    r.text = JSON.parse(r.text);
                    state.projekts.push(r);
                }
            });
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}