<template>
    <v-app id="app">
        <Navigation :lang="lang" v-if="lang !== undefined && lang !== 'cms'"/>
        <div v-if="lang !== undefined && lang !== 'cms'"><hr/></div>
        <transition name="fade" mode="out-in">
            <div id="wrapper">
                <router-view :lang="lang" v-if="lang !== undefined"/>
            </div>
        </transition>
        <CookieBanner v-if="lang !== undefined && lang !== 'cms'"/>
        <Footer :lang="lang" v-if="lang !== undefined && lang !== 'cms'"/>
    </v-app>
</template>

<script>
import "./style.scss";
import CookieBanner from "./components/CookieBanner";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

export default {
    name: "App",
    components: {
        Footer,
        Navigation,
		CookieBanner,
    },
    created() {
        if(this.$route.path.startsWith("/cms")) {
            this.lang = "cms";
        } else {
            let lang = this.$route.params.lang;
            if(lang === undefined || (lang !== "de" && lang !== "en")) {
                this.$router.replace("/de/")
                this.$router.go()
            } else {
                if(lang === "de" || lang === "en") {
                    if(this.$route.path.slice(-1) !== "/") {
                        window.history.pushState("object or string", document.title, this.$route.path + "/");
                    }
                } else {
                    this.$router.replace("/de/")
                }
            }
            this.lang = lang;
        }
    },
    data: () => ({
        lang: undefined,
    }),
}
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    .vue100vh {
        display: flex;
        flex-direction: column;
    }

    #wrapper {
        height: calc(100% - #{2*$margin});
        width: calc(100% - #{2*$margin});
        left: 0;
        display: flex;
        flex-direction: column;
        min-height: 90vh;
        margin: $margin;
    }


</style>

<style lang="scss">
    @import "src/main/vue/style";

    @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        src: url("/SourceSansPro.woff2") format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    .Cookie__button{
        width: fit-content!important;
    }

    .mainVideo {
        height: 56.4vw;
        margin-left: -$margin;
        margin-right: -$margin;
        width: 100vw;
    }

    .v-btn {
        width: fit-content!important;
    }

    .v-card__title {
        color: $mainColor!important;
        font-weight: 700;
    }
</style>
