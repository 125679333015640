import Vue from "vue";
import VueRouter from 'vue-router'

import router from './router.js';

import store from "./store/modules";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "./style.scss";

import App from './App.vue';

Vue.use(VueRouter)
Vue.use(Vuetify);

Vue.config.productionTip = false

new Vue({
  store,
  beforeCreate() {
    this.$store.commit("initializeStore");
  },
  router,
  vuetify : new Vuetify({
    theme: {
      themes: {
        light: {
          primary: "#1da95c",
          secondary: '#43a680',
          accent: '#74f6a7',
          error: '#b71c1c',
          white: "#fbf9fa",
        },
      },
    },
  }),
  render: h => h(App),
}).$mount('#app')