import VueRouter from "vue-router";
import store from "./store/modules";
import Startseite from "./pages/Startseite";
import Team from "./pages/Team";
import Film from "./pages/Film";
import Dienstleistungen from "./pages/Dienstleistungen";
import Foto from "./pages/Foto";
import Projekte from "./pages/Projekte";
import Artikel from "./pages/Artikel";
import Impressum from "./pages/Impressum";
import Auth from "./pages/cms/Auth";
import CMS from "./pages/cms/CMS";
import EditTeam from "./pages/cms/EditTeam";
import EditFilm from "./pages/cms/EditFilm";
import EditProjekt from "./pages/cms/EditProjekt";
import EditFoto from "./pages/cms/EditFoto";
import EditContact from "./pages/cms/EditContact";
import EditArtikel from "./pages/cms/EditArtikel";
import threesixty from "@/main/vue/pages/360";
import EditDienstleistungen from "./pages/cms/EditDienstleistungen";


const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Startseite,
        },
        {
            path: "/cms",
            component: CMS,
        },
        {
            path: "/cms/auth",
            component: Auth,
        },
        {
            path: "/cms/edit/team/:id",
            component: EditTeam,
        },
        {
            path: "/cms/edit/film/:id",
            component: EditFilm,
        },
        {
            path: "/cms/edit/foto/:id",
            component: EditFoto,
        },
        {
            path: "/cms/edit/dienstleistungen/:id",
            component: EditDienstleistungen,
        },
        {
            path: "/cms/edit/projekt/:id",
            component: EditProjekt,
        },
        {
            path: "/cms/edit/contact/:id",
            component: EditContact,
        },
        {
            path: "/cms/edit/artikel/:id",
            component: EditArtikel,
        },
        {
            path: "/:lang/",
            component: Startseite,
        },
        {
            path: "/:lang/360",
            component: threesixty,
        },
        {
            path: "/:lang/team",
            component: Team,
        },
        {
            path: "/:lang/film",
            component: Film,
        },
        {
            path: "/:lang/leistungen",
            component: Dienstleistungen,
        },
        {
            path: "/:lang/foto",
            component: Foto,
        },
        {
            path: "/:lang/projekte",
            component: Projekte,
        },
        {
            path: "/:lang/aktuelles",
            component: Artikel,
        },
        {
            path: "/:lang/impressum",
            component: Impressum,
        },
    ]
})

let loaded = false;

router.beforeEach((to, from, next) => {
    if(to !== undefined && to.matched !== undefined && to.matched[0] !== undefined) {
        if (to.matched[0].path.startsWith("/cms")) {
            if(!loaded) {
                store.commit("initializeStore");
                loaded = true;
            }
            store.dispatch("requestAuth").then(() => {
                checkCMS(to, next);
            }, () => {
                checkCMS(to, next);
            });
        }
    }
    next()
})

function checkCMS(to, next) {
    if (to.matched[0].path.startsWith("/cms/auth")) {
        if (store.getters.isAuthenticated) {
            next("/cms")
            return
        }
    } else {
        if (!store.getters.isAuthenticated) {
            next("/cms/auth")
            return
        }
    }
}

export default router
