<template>
    <v-container v-if="list !== []">
        <v-dialog v-model="dialog" width="fit-content">
            <v-card max-width="400px" style="margin: auto">
                <v-card-title>Möchtest du wirklich löschen?</v-card-title>
                <v-card-text>Du kannst diesen Vorgang nicht rückgänig machen!</v-card-text>
                <v-card-actions>
                    <v-btn @click="dialog = false">Abbrechen</v-btn>
                    <v-spacer/>
                    <v-btn @click="del()" color="error">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters>
            <v-col style="display: flex">
                <v-text-field v-model="neues" placeholder="Titel"/>
                <v-btn :disabled="neues === '' || neues.includes(' ')" class="my-2 mx-auto" style="margin-left: 5px; margin-right: auto" @click="add" color="primary">Erstellen</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters v-for="(t, i) in list" :key="t + i">
            <v-col v-if="t !== undefined">
                <v-toolbar class="ma-1">
                    <v-toolbar-title style="overflow: hidden">{{ t.date }} - {{ t.title }}</v-toolbar-title>
                    <v-spacer/>
                    <v-btn @click="$router.push('/cms/edit/artikel/' + t.id)" icon><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-divider vertical/>
                    <v-btn @click="dialog = true; selected = t.id" icon><v-icon>mdi-delete</v-icon></v-btn>
                </v-toolbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {

    name: "CMSArtikel",
    data: () => ({
        list: [],
        selected: null,
        dialog: false,
        neues: "",
    }),
    created() {
        this.load();
    },
    computed: {
        ...mapGetters("artikel", ["getList"]),
    },
    methods: {
        ...mapActions("artikel", ["requestList", "delete", "create"]),
        load() {
            this.requestList().then(() => {
                this.list = this.getList;
            })
        },
        del( ) {
            this.dialog = false;
            this.delete(this.selected).then(() => {
                this.load();
            });
        },
        add() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth()+1;
            let dt = date.getDate();

            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }
            let cmd = {
                title: this.neues,
                text: "",
                date: year+'-' + month + '-'+dt,
            }
            this.create(cmd).then(() => {
                this.load();
            })
        }
    }
}
</script>

<style scoped>

</style>