<template>
    <v-container v-if="elem !== undefined">
        <v-row no-gutters>
            <v-col style="display: flex">
                <v-btn class="my-2" style="margin-right: 5px;margin-left: auto" @click="save" color="primary">Speichern</v-btn>
                <v-btn class="my-2" style="margin-left: 5px; margin-right: auto" @click="$router.go(-1)" color="danger">Zurück</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-text-field class="mx-1" label="Title" v-model="elem.title" filled/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <ckeditor :editor="editor" v-model="elem.text" :config="editorConfig"></ckeditor>
            </v-col>
        </v-row>
        <v-divider/>
        <v-row no-gutters>
            <v-col>
                <v-date-picker v-model="elem.date" first-day-of-week="1" full-width scrollable :max="new Date().toISOString()"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CMSArtikel",
    components: {
        ckeditor: CKEditor.component
    },
    data: () => ({
        elem: undefined,
        editor: ClassicEditor,
        editorConfig: {
            // The configuration of the editor.
        }
    }),
    created() {
        this.load();
    },
    computed: {
        ...mapGetters("artikel", ["get"]),
    },
    methods: {
        ...mapActions("artikel", ["request", "edit"]),
        load() {
            this.request(this.$route.params.id).then(() => {
                this.elem = this.get;
            })
        },
        save() {
            let cmd = {
                id: this.$route.params.id,
                title: this.elem.title,
                text: this.elem.text,
                date: this.elem.date,
            }
            this.edit(cmd).then(() => {
                this.$router.go(-1);
            });
        },
    }
}
</script>

<style scoped>

</style>
