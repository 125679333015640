import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import api from "../api";
import artikel from "./artikel";
import contact from "./contact";
import film from "./film";
import foto from "./foto";
import dienstleistungen from "./dienstleistungen";
import pages from "./pages";
import projekt from "./projekt";
import team from "./team";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        artikel,
        contact,
        film,
        foto,
        dienstleistungen,
        pages,
        projekt,
        team,
    },
    state: {
        authenticated: false,
        token: false,
    },
    actions: {
        discardToken({commit}) {
            return new Promise((resolve) => {
                commit("clearAuthentication");
                resolve();
            })
        },
        requestAuth({commit}) {
            return new Promise((resolve, reject) => {
                api.auth.check().then(res => {
                    commit("check", res.data);
                    resolve();
                }).catch(() => {
                    commit("check", false);
                    reject();
                })
            })
        },
        requestToken({commit}, credentials) {
            return new Promise((resolve, reject) => {
                api.auth.login(credentials.username, credentials.password).then(res => {
                    commit("authenticate", "Bearer " + res.data.access_token);
                    resolve();
                }).catch(() => {
                    commit("authenticate", null);
                    reject();
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        uploadImage({commit}, image) {
            return new Promise((resolve, reject) => {
                api.upload(image.id, image.file).then(() => {
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        deleteImage({commit}, image) {
            return new Promise((resolve, reject) => {
                api.delete(image).then(() => {
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
    },
    getters: {
        isAuthenticated: (state) => {
            return state.authenticated
        },
    },
    mutations: {
        initializeStore() {
            if (localStorage.getItem("store")) {
                store.state.authenticated = true;
                Object.assign(store.state, JSON.parse(localStorage.getItem("store")));
            }
        },
        authenticate(state, token) {
            if (token !== null) {
                state.token = token;
                state.authenticated = true;
                axios.defaults.headers["Authorization"] = token;
            } else {
                state.authenticated = false;
            }
        },
        check(state, check) {
            this.state.authenticated = check;
            if(!check) {
                this.state.token = check;
            }
        },
        clearAuthentication() {
            this.state.token = null;
            this.state.authenticated = false;
        },
    },
})

store.subscribe((mutation, state) => {
    localStorage.setItem("store", JSON.stringify(state));
    axios.defaults.headers["Authorization"] = state.token
});

export default store
