<template>
    <v-container class="auth">

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">Falsche Anmeldedaten</v-card-title>
                <v-card-text>Bitte probieren Sie sich erneut anzumelden.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false; $router.push('/de'); $router.go()">Zurück zur Startseite</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-form ref="form" v-model="valid" @submit="login" onsubmit="return false">
            <v-row no-gutters align="center" class="top">
                <v-col>
                    <h1>CMS - Anmeldung</h1>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col>
                    <v-text-field label="E-Mail" v-model="auth.username" type="mail" :rules="[v => !!v || 'E-Mail' + text.notwendig]" filled required/>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col>
                    <v-text-field label="Passwort" v-model="auth.password" :rules="[v => !!v || 'Passwort' + text.notwendig]" @click:append="show = !show" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" filled required/>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col>
                    <v-btn type="submit" :disabled="!valid" color="primary" large>Login</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>


<script>
    import {mapActions} from "vuex";

    export default {
        name: "Auth",
        data() {
            return {
                auth: {
                    username: "",
                    password: "",
                },
                text: {
                    notwendig: " ist erforderlich."
                },
                show: false,
                dialog: false,
                valid: false,
            }
        },
        methods: {
            ...mapActions(["requestToken"]),
            login() {
                if(this.valid) {
                    this.requestToken(this.auth).then(() => {
                        this.$router.push("/cms");
                    }, () => {
                        this.dialog = true;
                    });
                }
            }
        }

    }
</script>


<style scoped lang="scss">
    @import "src/main/vue/style";

    .container {
        padding: 5rem;
        background-color: $background;
    }
    .col {
        display: flex;
        text-align: center;
    }
    .auth, .v-btn, h1 {
        margin: auto;
    }
    h1 {
        color: $mainColor;
    }
    .top {
        margin-bottom: 2rem;
    }
    .headline {
        word-break: break-word;
    }
</style>
