import axios from "axios";

export default {
    get(id) {
        return axios.get("/api/pages/" + id);
    },
    list() {
        return axios.get("/api/pages/list");
    },
    update(object) {
        if(object.id.endsWith("Startseite")) {
            let array = object.text.split("\n");
            object.text = JSON.stringify(array);
        }
        return axios.put("/api/pages/" + object.id, object);
    },
}
