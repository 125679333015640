<template>
    <div class="content" v-if="page !== undefined && lang !== undefined"><!-- Head -->
        <h1 class="centerText">{{ page.title }}</h1>
        <p class="centerText">{{ page.text }}</p>

        <!-- Content -->
        <span v-if="news !== undefined">
            <v-row no-gutters v-for="(artikel, i) in news.slice(0, count)" :key="i">
                <v-spacer/>
                    <v-col cols="8">
                        <v-lazy>
                            <v-card color="white" class="ma-2">
                                <v-card-title>{{ artikel.title }}</v-card-title>
                                <v-card-text v-html="artikel.text"/>
                                <v-card-subtitle style="text-align: right">{{ new Date(artikel.date).toLocaleDateString() }}</v-card-subtitle>
                            </v-card>
                        </v-lazy>
                    </v-col>
                <v-spacer/>
            </v-row>
            <v-row no-gutters v-if="count < news.length">
                <v-col cols="12" style="display: flex;">
                    <v-btn @click="count += 10" x-large style="margin: auto; width: fit-content">Mehr laden</v-btn>
                </v-col>
            </v-row>
        </span>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "News",
        data: () => ({
            name: "News",
            page: undefined,
            news: undefined,
            count: 5,
        }),
        props: {
            lang: String,
        },
        created() {
            this.requestPage(this.id).then(() => {
                this.page = this.getPage;
            })
            this.requestList().then(() => {
                this.news = this.getList;
            })
        },
        computed: {
            ...mapGetters("pages", ["getPage"]),
            ...mapGetters("artikel", ["getList"]),
            id() {
                return this.lang + "-" + this.name;
            },
        },
        methods: {
            ...mapActions("pages", ["requestPage"]),
            ...mapActions("artikel", ["requestList"]),
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/main/vue/style";

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .noBreak {
            white-space: nowrap;
        }

        .centerText {
            text-align: center;
            max-width: 66%;
            margin-left: auto;
            margin-right: auto;
        }

        .video {
            height: 52vw;

            @media #{$breakToTablet} {
                height: 17.5vw;
            }
        }
    }
</style>