<template>
    <div style="width: 100%">
        <v-row no-gutters>
            <v-col>
                <v-tabs v-model="tab">
                    <v-tab>Seiten</v-tab>
                    <v-tab>Team</v-tab>
                    <v-tab>Film</v-tab>
                    <v-tab>Foto</v-tab>
                    <v-tab>Dienstleistungen</v-tab>
                    <v-tab>Projekte</v-tab>
                    <v-tab>Kontakt</v-tab>
                    <v-tab>Aktuelles</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row style="margin-bottom: 7vh">
            <v-col>
                <v-tabs-items v-model="tab">
                    <v-tab-item><Seiten/></v-tab-item>
                    <v-tab-item><TeamUndJobs/></v-tab-item>
                    <v-tab-item><CMSFilm/></v-tab-item>
                    <v-tab-item><CMSFoto/></v-tab-item>
                    <v-tab-item><CMSDienstleistungen/></v-tab-item>
                    <v-tab-item><CMSProjekte/></v-tab-item>
                    <v-tab-item><CMSKontakt/></v-tab-item>
                    <v-tab-item><CMSArtikel/></v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-bottom-navigation fixed height="7vh">
            <v-btn @click="$router.push('/de'); $router.go();" color="secondary" class="mx-2">
                <span>Zurück</span>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn @click="logout()" color="secondary" class="mx-2">
                <span>Ausloggen</span>
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Seiten from "../../components/cms/Seiten";
    import TeamUndJobs from "../../components/cms/TeamUndJobs";
    import CMSFilm from "../../components/cms/CMSFilm";
    import CMSProjekte from "../../components/cms/CMSProjekte";
    import CMSFoto from "../../components/cms/CMSFoto";
    import CMSKontakt from "../../components/cms/CMSContact";
    import CMSArtikel from "@/main/vue/components/cms/CMSArtikel";
    import CMSDienstleistungen from "@/main/vue/components/cms/CMSDienstleistungen";

    export default {
        name: "CMS",
        components: {CMSDienstleistungen, CMSArtikel, CMSKontakt, CMSFoto, CMSProjekte, TeamUndJobs, Seiten, CMSFilm},
        data: () => ({
            tab: null,
        }),
        methods: {
            ...mapActions(["discardToken"]),
            logout() {
                this.discardToken().then(() => {
                    this.$router.push("/de/");
                    this.$router.go();
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .date {
        font-size: 0.8rem;
    }
</style>
