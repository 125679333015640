import axios from "axios";

export default {
    getList() {
        return axios.get("/api/team/list");
    },
    get(id) {
        return axios.get("/api/team/get/" + id);
    },
    getJobs() {
        return axios.get("/api/team/jobs");
    },
    delete(id) {
        return axios.delete("/api/team/delete/" + id);
    },
    update(object) {
        return axios.put("/api/team/update/" + object.id, object);
    },
    create() {
        let cmd = {
            name: "Neuer Mitarbeiter",
            position: "{\"de\":\"Text DE 1\",\"en\":\"Text EN 1\"}",
            text: "[\"Text\"]",
        }
        return axios.post("/api/team/create", cmd);
    },
    moveDown(id) {
        return axios.post("/api/team/move/" + id);
    },
}
